<template>
  <div class="about">
    <div class="page-wrapper">
      <div class="stricky-header stricked-menu main-menu">
        <div class="sticky-header__content"></div>
      </div>
      <section class="page-header">
        <div class="page-header-bg"></div>
        <div class="page-header-bg-overly"></div>
        <div class="container">
          <div class="page-header__inner">
            <p class="title wow animate__animated animate__fadeInDown">
              以永不停止的自进化力&nbsp;&nbsp;&nbsp;承载企业业务边界
            </p>
          </div>
        </div>
      </section>

      <section class="about-two">
        <div class="container">
          <div class="row">
            <div class="col-xl-6">
              <div class="about-two__right">
                <div class="section-title text-left">
                  <img src="@/assets/img/logo.png" alt="" class="section-title-logo" />
                </div>
                <div class="about-two__text-1" style="margin-left: 15px">
                  <!-- 公司概述：
                  重庆璐付科技有限公司（简称：璐付科技），成立于2014年4月。公司以实现“国内企业数字化转型”为使命，主要提供文娱、游戏、通信及生活服务相关行业的数字商品及服务，包括通过“权益营销、积分平台、开放
                  API”等系统为企业用户提供全面的产品数字化、场景营销和员工福利等服务。总部地址位于重庆南岸区。
                  <br />
                  <br />
                  公司简介： -->
                  &nbsp;&nbsp;&nbsp;璐付科技是一家专注于为企业提供数字化会员权益及企业员工的福利的国家级高新技术服务型企业，同时也是“专精特新”企业。
                  <br />
                  &nbsp;&nbsp;&nbsp;公司以“会员权益、福利平台、璐付云”为核心，为企业提供全面的智能场景营销工具、会员权益解决方案和员工福利解决方案。以数字化服务平台为载体，精准匹配企业平台和消费者三方需求，重塑和优化传统会员权益及员工福利，构建以“生活圈数字产品”生态服务体系，整合了丰富的实物及虚拟卡券供应链，并结合本地生活服务，为B端企业赋智、赋能、赋值，为C
                  端用户提供深度服务。凭借在运营商、银行、保险、车企、消费金融、商业、电商等领域的深厚积累和丰富经验。璐付科技已与三千多家企业建立长期合作关系。
                  <br />
                  &nbsp;&nbsp;&nbsp;我们为企业提供定制服务，借助场景化工具制定运营方案，帮助企业提升营销价值，实现会员用户增长、留存和促活。我们还有成熟的福利方案和完善服务，提供商城、平台品牌精选及定制服务。通过多样化福利形式，覆盖全场景，为企业全面提供专业的员工福利解决方案。
                </div>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="about-two__left wow slideInUp" data-wow-delay="100ms" data-wow-duration="2500ms">
                <div class="section-title text-right"><span class="section-title__tagline">2014</span></div>
                <div class="section-title text-right"><span>since</span></div>
                <div class="about-two__img">
                  <img src="../../assets/picture/about-two-img-1.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--About Two End-->

      <div class="container" style="background-color: red; padding: 0">
        <Time />
      </div>

      <!--Brand Two Start-->
      <section class="page-header two">
        <div class="page-header-bg"></div>
        <div class="page-header-bg-overly"></div>
        <div class="container">
          <div class="page-header2__inner">
            <p class="header" style="color: rgba(0, 0, 0, 0.8); font-size: 22px">服务成就</p>
            <p style="color: rgba(0, 0, 0, 0.8); font-size: 16px; font-weight: 400; margin-top: 50px">
              用服务铸就品牌，用品牌创造价值
            </p>
          </div>
          <div class="row">
            <div class="col-xl-3">
              <div class="share-the-joy__trusted wow fadeIn" data-wow-duration="1500ms">
                <span class="icon-social-media"></span>
                <div class="share-the-joy__trusted__content">
                  <p class="odometer"><CountUp :endVal="2" :startVal="0" />亿+</p>
                  <p class="odosmall">服务用户人次</p>
                </div>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="share-the-joy__trusted wow fadeIn" data-wow-duration="1500ms">
                <span class="icon-social-media"></span>
                <div class="share-the-joy__trusted__content">
                  <p class="odometer" data-count="1.03">
                    <CountUp :endVal="10" :startVal="0" />
                    万+
                  </p>
                  <p class="odosmall">供应商品数</p>
                </div>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="share-the-joy__trusted wow fadeIn" data-wow-duration="1500ms">
                <span class="icon-social-media"></span>
                <div class="share-the-joy__trusted__content">
                  <p class="odometer" data-count="6000"><CountUp :endVal="4000" :startVal="0" />+</p>
                  <p class="odosmall">客户数量</p>
                </div>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="share-the-joy__trusted wow fadeIn" data-wow-duration="1500ms">
                <span class="icon-social-media"></span>
                <div class="share-the-joy__trusted__content">
                  <p class="odometer" data-count="100000"><CountUp :endVal="6000" :startVal="0" />+</p>
                  <p class="odosmall">合作商家数量</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--Brand Two End-->

      <!--About Three Start-->

      <section class="about-three">
        <div class="container">
          <div class="row row-about-three">
            <div class="col-xl-4">
              <div class="contact1">关于我们</div>
              <div class="contact2">
                <img src="@/assets/img/about-address.png" class="address" />
                <div>
                  <div class="phone-text">重庆总公司地址：重庆市江北区北滨一路招商江湾城363号附23号3楼</div>
                  <div class="phone-text">成都分公司地址：成都武侯区新希望B座2408B</div>
                </div>
              </div>
              <div class="contact3 flex">
                <img src="@/assets/img/about-phone.png" class="phone" />
                <div>
                  <div class="phone-text">客服电话：4000022253</div>
                  <div class="phone-text">7*24小时</div>
                </div>
              </div>
              <div class="contact3 flex">
                <div>
                  <div class="phone-text">企业愿景</div>
                  <div class="phone-text">创造价值 经营幸福 改变生活</div>
                </div>
              </div>
              <div class="contact3 flex">
                <div>
                  <div class="phone-text">企业使命</div>
                  <div class="phone-text">以永不停止的自进化力&nbsp;&nbsp;&nbsp;承载企业业务边界</div>
                </div>
              </div>
            </div>
            <div class="col-xl-8">
              <!-- <div id="mymap"> -->
              <div style="padding-bottom: 20px" class="about-end-img">
                <img src="@/assets/img/about-bg.jpeg" />
              </div>
              <!-- <baidu-map :center="center" :zoom="zoom" @ready="handler" class="map">
                  <bm-marker
                    :position="{ lng: center.lng, lat: center.lat }"
                    :dragging="true"
                    animation="BMAP_ANIMATION_BOUNCE"
                  >
                    <bm-info-window :show="true">重庆璐付科技有限公司</bm-info-window>
                  </bm-marker>
                </baidu-map> -->
            </div>
          </div>
          <!-- </div> -->
        </div>
      </section>

      <!--Site Footer One End-->
    </div>
    <!-- /.page-wrapper -->

    <div class="mobile-nav__wrapper">
      <div class="mobile-nav__overlay mobile-nav__toggler"></div>
      <!-- /.mobile-nav__overlay -->
      <div class="mobile-nav__content">
        <span class="mobile-nav__close mobile-nav__toggler"><i class="fa fa-times"></i></span>

        <div class="logo-box">
          <a href="index.html" aria-label="logo image"
            ><img src="static/picture/footer-logo.png" width="155" alt=""
          /></a>
        </div>
        <!-- /.logo-box -->
        <div class="mobile-nav__container"></div>
        <!-- /.mobile-nav__container -->

        <ul class="mobile-nav__contact list-unstyled">
          <li>
            <i class="fa fa-envelope"></i>
            <a href="mailto:hezuo@lupay.cn">hezuo@lupay.cn</a>
          </li>
          <li>
            <i class="fa fa-phone-alt"></i>
            <a href="tel:400-002-2253">400 002 2253</a>
          </li>
        </ul>
      </div>
    </div>

    <a href="#" data-target="html" class="scroll-to-target scroll-to-top"><i class="fa fa-angle-up"></i></a>
  </div>
</template>

<script>
import CountUp from '@wjtools/vue-countup';
import Time from '@/components/common/Time';

export default {
  components: {
    CountUp,
    Time
  },
  data() {
    return {
      center: { lng: 0, lat: 0 },
      zoom: 3
    };
  },
  mounted() {
    new this.$wow.WOW().init({
      //新建实列
      boxClass: 'wow', //class名字
      animateClass: 'animate__animated', //animateclass动画库类名,和当前animate动画库决定
      offset: 0, //到元素距离触发动画（当默认为0）
      mobile: true, //在移动设备上触发动画（默认为true）
      live: true //对异步加载的内容进行操作（默认为true）
    });
  },
  methods: {
    handler({ BMap, map }) {
      console.log(BMap, map);
      this.center.lng = 106.567938;
      this.center.lat = 29.540083;
      this.zoom = 15;
    }
  }
};
</script>

<style scoped src="../../assets/cmomon/bootstrap-select.min.css"></style>
<style scoped src="../../assets/cmomon/bootstrap.min.css"></style>
<style scoped src="../../assets/cmomon/owl.carousel.min.css"></style>
<!-- <style scoped src="../../assets/cmomon/style.css"></style> -->
<style scoped src="../../assets/cmomon/izeetak-responsive.css"></style>
<style scoped src="../../assets/cmomon/izeetak.css"></style>

<style lang="less" scoped>
.page-header-bg {
  .lupay-text {
    color: rgba(255, 255, 255, 1) !important;
    font-size: 40px;
    line-height: 30px;
    font-weight: 700;
  }
}
.contact2,
.contact3 {
  position: relative;
  .phone {
    max-width: 18.27px;
    max-height: 24px;
    position: absolute;
    top: 15px;
  }
  .address {
    width: 22.95px;
    height: 24px;
    position: absolute;
    top: 1px;
  }
  .phone-text {
    padding-left: 40px;
    color: rgba(0, 0, 0, 0.8) !important;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;
    margin: 15px 0 !important;
  }
}
.about-two__img {
  img {
    transform: scale(1);
    transition-duration: 300ms;
  }
  img:hover {
    transform: scale(1.1);
    transition-duration: 300ms;
  }
}

.about-end-img {
  img {
    transform: scale(1);
    transition-duration: 300ms;
  }
  img:hover {
    transform: scale(1.05);
    transition-duration: 300ms;
  }
}

.map {
  width: 100%;
  height: 100%;
}
</style>
